import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  displayContactUs = false;

  constructor() {}
}
