export const environment = {
  production: true,
  apiUrl: `https://api.qa.cgiar.org/api`,
  tawkToId: '5ea2f28869e9320caac6f637',
  ga: 'UA-167825249-2',
  phase: 3,
  prUrl: `https://reporting.cgiar.org/`,
  footerUrls: {
    termsAndCondition: 'https://cgiar-prms.notion.site/Legal-Terms-eb2f3e436f3e46bd9057b1cb1e3391b8',
    license: 'https://github.com/AllianceBioversityCIAT/QA/blob/master/LICENSE'
  },
}
