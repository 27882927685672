<div class="row align-items-start">
    <div class="col-sm-2 card-left">
        <h4 class="">Admin Menu</h4>

        <div class="btn-group-vertical col">
            <button type="button" (click)="selectRole('')" class="btn btn-secondary">All</button>
            <button type="button" (click)="selectRole(allRoles.admin)" class="btn btn-secondary">Assign
                Administrator</button>
            <button type="button" (click)="selectRole(allRoles.asesor)" class="btn btn-secondary">Assign
                Assessors</button>
            <button type="button" (click)="selectRole(allRoles.guest)" class="btn btn-secondary">Assign Guest</button>
        </div>
    </div>

    <div class="col-md-6 admin-users-container">
        <h2 class="header">List of Evaluators</h2>
        <div class="users-card-container">
            <form [formGroup]="editUserForm">
                <div class="card" *ngFor="let user of users | filterByRole:selectedRole; let i=index "
                    formArrayName="editUserFormArray">
                    <div class="card-header row justify-content-between" id="heading-{{user.id}}">
                        <a (click)="user.isCollapsed = !user.isCollapsed "
                            [attr.aria-expanded]="!user.isCollapsed" aria-controls="collapseBasic">{{user.username}} (
                            {{user.email}} )</a>
                        <div class="button-card-container">
                            <!--  -->
                            <button (click)="user.isEditing = true; user.isCollapsed = false" type="button"
                                *ngIf="!user.isEditing" class="btn">
                                <i class="icon-edit"></i>
                            </button>
                            <!--  -->
                            <button (click)="openModal(template, 'edit', user)" type="button" *ngIf="user.isEditing"
                                class="btn">
                                <i class="icon-save"></i>
                            </button>
                            <!--  -->
                            <button (click)="openModal(template, 'delete', user)" type="button " class="btn">
                                <i class="icon-remove"></i>
                            </button>
                        </div>
                    </div>
                    <!--  *ngIf="user.role === allRoles.asesor && !user.isEditing" -->
                    <div id="collapse-{{user.id}}" [collapse]="user.isCollapsed" [isAnimated]="true">
                        <div class="card-body" *ngIf="user.indicators.length > 0">
                            Assigned Indicators:
                            <div class="indicators-container">
                                <p *ngFor="let indicator of user.indicators">
                                    {{indicator.indicator.name}}
                                </p>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="!user.indicators">
                            None Assigned Indicators
                        </div>

                    </div>
                    <!-- <div id="collapse-{{user.id}}" *ngIf="user.isEditing" [collapse]="user.isCollapsed"
                        [isAnimated]="true">
                        <div class="card-body">
                            <div [formGroup]="editUserFormArray.controls[i]" class="edit-user-form">
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" formControlName="name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && g.name.errors }" />
                                    <div *ngIf="submitted && g.name.errors" class="invalid-feedback">
                                        <div *ngIf="g.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && g.email.errors }" />
                                    <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
                                        <div *ngIf="g.email.errors.required">Email is required</div>
                                        <div *ngIf="g.email.errors.email">Incorrect email format</div>
                                    </div>
                                </div>

                                <div class="form-group indicators" >
                                    <label for="indicators">Assign Indicators</label>
                                    <type-ahead multi="true" formControlName="indicators"
                                        [suggestions]="suggestedIndicators">
                                    </type-ahead>
                                </div>

                            </div>
                            
                        </div>
                    </div> -->
                </div>
            </form>
        </div>
        <div *ngIf="selectedRole !==''" class="admin-add-users">
            <p>Add {{selectedRole}} </p>
            <div class="card">
                <div class="card-body">
                    <!-- /(ngSubmit)="onSubmit()" -->
                    <form [formGroup]="addUserForm" class="add-user-form">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" formControlName="name" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" formControlName="email" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Incorrect email format</div>
                            </div>
                        </div>
                        <div class="form-group indicators" >
                            <label for="indicators">Assign Indicators</label>
                            <type-ahead multi="true" formControlName="indicators" [suggestions]="suggestedIndicators">
                            </type-ahead>
                        </div>
                        <div class="form-group col-4">
                            <button [disabled]="loading" (click)="addUser()" class="btn btn-primary">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Add
                            </button>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
</div>




<!-- confirmation modal HTML -->
<ng-template #template>
    <div class="modal-body text-center">
        <h4>{{confirmationData.title}}</h4>
        <p>{{confirmationData.text}}</p>
        <button type="button" class="btn btn-primary" (click)="confirm(confirmationData)">Confirm</button>
        <button type="button" class="btn btn-default" (click)="decline()">Cancel</button>
    </div>
</ng-template>

<!-- Loading Spinner -->
<ngx-spinner>
    <p class="loading">Loading...</p>
</ngx-spinner>