<!-- <div *ngIf="!env.production" class="test-env">
    <h1>Testing Environment</h1>
</div> -->
<header-bar></header-bar>
<alert></alert>


<div class="jumbotron">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>




<!-- If not chrome -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-auto-name" class="modal-title pull-left">Change browser for full Quality Assessment
                    platform experience</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Currently, we are working to support this browser.
                    We highly recomend you use <a href="https://www.google.com/intl/es-419/chrome/">Google Chrome</a>.
                </p>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>